// tagify
@import "~@yaireo/tagify/dist/tagify.css";

// perfect-scrollbar
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// simplemde
@import "~simplemde/dist/simplemde.min.css";

// simplelightbox
@import "~simplelightbox/dist/simple-lightbox.css";

.sl-overlay {
  z-index: 999999;
  background: #000;
}

.simple-lightbox {
  z-index: 9999999;

  .sl-close {
    color: #fff;
  }
}

.CodeMirror.cm-s-paper.CodeMirror-wrap {
  min-height: auto;
  height: 100%;
}

.jscolor-picker-wrap {
  z-index: 999999999 !important;
}

.lightpick {
  z-index: 999999999 !important;
}
